import { useEffect } from "react"
import LoadingSpinner from "app/components/LoadingSpinner"
import { GetServerSideProps } from "next"
import { ALLOW_PARAMS_FOR_SEM_TRACE } from "app/utils/constants"

const WmConnectRedirect = () => {
  useEffect(() => {
    window.location.replace("/documents")
  }, [])

  return <LoadingSpinner />
}

const setSemTraceCookie = (context, cookieName, modifiedQueryParams) => {
  const isSecure = process.env.NODE_ENV === "production"
  context.res.setHeader(
    "Set-Cookie",
    `${cookieName}=${encodeURIComponent(JSON.stringify(modifiedQueryParams))}; Path=/; ${
      isSecure ? "Secure; " : ""
    }SameSite=Strict; HttpOnly`
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  const queryParams = query
  const filteredParams = Object.keys(queryParams)
    .filter((key) => ALLOW_PARAMS_FOR_SEM_TRACE.includes(key))
    .reduce((obj, key) => {
      if (queryParams[key]) {
        obj[key] = queryParams[key]
      }
      return obj
    }, {})
  const modifiedQueryParams = {
    ...filteredParams,
  }
  const hasRedirectedWithParam = Object.keys(filteredParams).length > 0
  const cookieName = "semTrace"

  if (hasRedirectedWithParam) {
    // Redirected to WillMaker with query params
    // Always set query params to cookie when redirected with it
    setSemTraceCookie(context, cookieName, modifiedQueryParams)
  }

  return { props: {} }
}

export default WmConnectRedirect
